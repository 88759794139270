import { Link } from 'gatsby';
import React from 'react';

import logo from '../../assets/images/integrations-homepage-02.svg';

export default function Integration() {
  return (
    <div className="integration-section">
      <div className="container">
        <div className="title">
          <h2>Integrate with your existing workflow</h2>
        </div>
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <div className="description">
          <p>
            Supercharge your workflow and leverage the data you already have, and the tools that you already use, with Fibonacci. Surface valuable information and make sense out of the chaos with Fibonacci.
            </p>
        </div>
        <div className="button-section">
          <Link style={{ textDecoration: 'none' }} to="/features/"><button className="base-button -primary">View all features</button></Link>
        </div>
      </div>
    </div>
  )
}
