import React, { useEffect, useState } from 'react';

import VideoPlayer from '../VideoPlayer';

import videoSample from '../../assets/images/video-sample.jpg';
import mock from '../../assets/images/mock.svg';
import play from '../../assets/images/play.svg';
import Typed from 'react-typed';


export default function VideoSection({ showMenu, setShowMenu, showModal, setShowModal }) {
  const [videoPlayerOpen, setVideoPlayerOpen] = useState(false);

  return (
    <div style={{ marginTop: '150px' }}>
      <VideoPlayer
        open={videoPlayerOpen}
        onClose={() => setVideoPlayerOpen(false)}
      />
      <div className="content">
        <div className="slider-container">

          <div className="fib-slide home-slide">
            <div className="banner">
              <h1>
                Do more, with less <span className="purple text-typed">
                  <Typed
                    strings={['email', 'spreadsheets', 'phone-calls', 'chaos']}
                    typeSpeed={60}
                    backSpeed={45}
                    backDelay={1300}
                    showCursor={false}
                    loop
                  />
                </span>
              </h1>
              <p>A better way to work, together.</p>
              <button onClick={() => setShowModal(!showModal)} style={{ margin: '0 auto' }} className="base-button -primary -center lg-show">Request a demo</button>
              <button onClick={() => setShowMenu(!showMenu)} style={{ margin: '0 auto' }} className="base-button -primary -center sm-show">Request a demo</button>
            </div>
            <div className="video-container">
              <div
                className="video"
                role="button"
                tabIndex="-1"
                onClick={() => {
                  setVideoPlayerOpen(true);
                }}
              >
                <div
                  className="play"
                >
                  <img src={play} className="icon" alt="play icon" />
                </div>
                <img className="video" src={videoSample} alt="" />
              </div>
              <img src={mock} className="mock" alt="Fibonacci Screenshot" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
