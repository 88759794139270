import { Link } from 'gatsby';
import React from 'react';

import twoBirdsDark from '../../assets/images/bird&bird--dark.png';
import simmons from '../../assets/images/simmons-and-simmons--dark.png';
import barclaysEagleLabs from '../../assets/images/barclays-eagle-labs-02.png';
import paulHastings from '../../assets/images/paul-hastings--full--dark.png';
import ashurst from '../../assets/images/ashurst--dark.png';
import addleshawGoddard from '../../assets/images/addleshaw-goddard--dark.png';
import pinsentMasons from '../../assets/images/pinsent-masons--dark.png';
import dentons from '../../assets/images/dentons--dark.png';
import duffPhelps from '../../assets/images/duff-phelps--dark.png';
import linklaters from '../../assets/images/linklaters--dark.png';

export default function Steering() {
  return (
    <div className="steering-section container">
      <h2 className="title">A true legal industry platform designed to improve the experience and delivery of legal services</h2>
      <div className="header">
        Join our
      <Link to="/steering-group/" className="link colored"> Steering Group</Link>
      </div>
      <div className="logos">
        <img className="logo twoBirdsDark" src={twoBirdsDark} alt="" />
        <img className="logo simmons" src={simmons} alt="" />
        <img className="logo barclaysEagleLabs" src={barclaysEagleLabs} alt="" />
        <img className="logo paulHastings" src={paulHastings} alt="" />
        <img className="logo ashurst" src={ashurst} alt="" />
        <img className="logo addleshawGoddard" src={addleshawGoddard} alt="" />
      </div>
      <div className="logos">
        <img className="logo pinsentMasons" src={pinsentMasons} alt="" />
        {false && <img className="logo dentons" src={dentons} alt="" />}
        <img className="logo duffPhelps" src={duffPhelps} alt="" />
        {false && <img className="logo linklaters" src={linklaters} alt="" />}
      </div>
      <div className="logos-sm">
        <img className="logo twoBirdsDark" src={twoBirdsDark} alt="" />
        <img className="logo simmons" src={simmons} alt="" />
        <img className="logo barclaysEagleLabs" src={barclaysEagleLabs} alt="" />
        <img className="logo paulHastings" src={paulHastings} alt="" />
      </div>
      <div className="logos-sm">
        <img className="logo ashurst" src={ashurst} alt="" />
        <img className="logo addleshawGoddard" src={addleshawGoddard} alt="" />
        <img className="logo pinsentMasons" src={pinsentMasons} alt="" />
      </div>
      <div className="logos-sm">
        {false && <img className="logo dentons" src={dentons} alt="" />}
        <img className="logo duffPhelps" src={duffPhelps} alt="" />
        {false && <img className="logo linklaters" src={linklaters} alt="" />}
      </div>
    </div>
  )
}
