import React, { useState, useRef, useEffect } from 'react'

import { Link } from 'gatsby'
import VimeoPlayer from '@vimeo/player'

import SwiperCore, { Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import play from "../../assets/images/icons/play.svg";
import logo from "../../assets/images/masterclass/masterclass-by-la-ventures.png"
import teaser1 from "../../assets/images/masterclass/teaser-1.png"
import teaser2 from "../../assets/images/masterclass/teaser-2.png"
import teaser3 from "../../assets/images/masterclass/teaser-3.jpg"
import teaser5 from "../../assets/images/masterclass/teaser-5-mobile.png"
import lars from "../../assets/images/masterclass/lars-rasmussen.png"
import david from "../../assets/images/masterclass/david-kerr.png"
import jason from "../../assets/images/masterclass/jason-barnwell.png"
import catherine from "../../assets/images/masterclass/catherine-goodman.png"
import helga from "../../assets/images/masterclass/helga-butcher.png"
import stephanie from "../../assets/images/masterclass/stephanie-hamon.png"
import chris from "../../assets/images/masterclass/chris-grant-2.png"
import ralph from "../../assets/images/masterclass/ralph-baxter-2.png"

import CloseIcon from '../../assets/images/icons/close-white.svg';

SwiperCore.use([Controller])

const MobilePlayer = ({ currentVideo: {source}, onClose }) => {
  const player = useRef(null)
  const [playing, setPlaying] = useState(false)
  const playerController = useRef(null)

  useEffect(() => {
    if (player != null) {
      playerController.current = new VimeoPlayer(player.current)
      playerController.current.on('pause', () => {
        setPlaying(false)
      })
      playerController.current.on('play', () => {
        setPlaying(true)
      })
    }

    document.getElementsByTagName('html')[0].style.setProperty('overflow-y', 'hidden', 'important')

    return () => {
      document.getElementsByTagName('html')[0].style.setProperty('overflow-y', 'auto', 'important')
    }
  }, [])

  return (
    <div className="mobile-teaser-player">
      <button className="close-button" onClick={() => onClose()}>
        <img src={CloseIcon} />
      </button>
      <div style={{ position: 'relative', width: '100%' }}>
        <div
          style={{ padding: '42.19% 0 0 0', position: 'relative' }}
        >
          <iframe
            ref={player}
            src={source}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            title="Teaser 1 - Visionaries, Innovators, and Dreamers">
          </iframe>
        </div>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  )
}

const MasterclassMobile = () => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentVideo, setCurrentVideo] = useState(null)

  return (
    <div className="masterclass-mobile-section">
      <div className="masterclass-mobile fit-width">
        <div className="masterclass-logo">
          <img src={logo} style={{ height: 64 }} />
        </div>
        <div className="teaser-thumbnail-container">
          <img src={teaser1} className="teaser-thumbnail" />
        </div>
        <button
          className="watch-button"
          onClick={() => {
            setIsPlaying(true)
            setCurrentVideo({
              source: "https://player.vimeo.com/video/706365581?h=6a10e91787&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1"
            })
          }}
        >
          <div className="play-icon">
            <img src={play} />
          </div>
          <div>Watch Trailer</div>
        </button>
        <h1 className="title">
          The Industry Titans
        </h1>
        <p className="subtitle">
          Join our Visionaries, our Innovators, and our Dreamers as they share their insights on legal innovation.
        </p>
        <div className="speakers-header">
          <div className="label">Speakers</div>
          <div className="learn-more-button">
            <Link to="/learn/">Learn more</Link>
          </div>
        </div>
        <div
          className="speakers"
        >
          <div
            className="speaker"
            onClick={() => {
              setIsPlaying(true)
              setCurrentVideo({
                source: 'https://player.vimeo.com/video/708644203?h=8a1ce96fcd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
              })
            }}
          >
            <img src={lars} />
          </div>
          <div
            className="speaker"
            onClick={() => {
              setIsPlaying(true)
              setCurrentVideo({
                source: 'https://player.vimeo.com/video/708644405?h=84f877b72e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
              })
            }}
          >
            <img src={david} />
          </div>
          <div
            className="speaker"
            onClick={() => {
              setIsPlaying(true)
              setCurrentVideo({
                source: 'https://player.vimeo.com/video/708794592?h=99b2a6349b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
              })
            }}
          >
            <img src={jason} />
          </div>
        </div>
        {isPlaying &&
          (<MobilePlayer
            onClose={() => setIsPlaying(false)}
            currentVideo={currentVideo}
          />)
        }
      </div>
    </div>
  )
}

const MasterclassMobile2 = () => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentVideo, setCurrentVideo] = useState(null)

  const [activeSlide, setActiveSlide] = useState(0)
  const [firstSwiper, setFirstSwiper] = useState(null)
  const [secondSwiper, setSecondSwiper] = useState(null)

  return (
    <div className="masterclass-mobile-section">
      <div className="masterclass-mobile fit-width">
        <div className="masterclass-logo">
          <img src={logo} style={{ height: 64 }} />
        </div>
      </div>
      <div className="masterclass-mobile-content" style={{ position: 'relative' }}>
        <Swiper
          loop
          onSwiper={setFirstSwiper}
          controller={{ control: secondSwiper }}
          onSlideChange={(s) => {
            setActiveSlide(s.realIndex)
            setIsPlaying(false)
          }}
        >
          <SwiperSlide>
            <div className="teaser-thumbnail-container">
              <img src={teaser3} className="teaser-thumbnail -center" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="teaser-thumbnail-container">
              <img src={teaser2} className="teaser-thumbnail" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="teaser-thumbnail-container">
              <img src={teaser1} className="teaser-thumbnail" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="teaser-thumbnail-container">
              <img src={teaser5} className="teaser-thumbnail" />
            </div>
          </SwiperSlide>
        </Swiper>
        <button
          className="watch-button mx-auto"
          style={{ marginTop: 24 }}
          onClick={() => {
            if (activeSlide === 0) {
              setCurrentVideo({
                source: "https://player.vimeo.com/video/718643443?h=09a7dc6124&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1"
              })
            } else if (activeSlide === 1) {
              setCurrentVideo({
                source: "https://player.vimeo.com/video/718985745?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1"
              })
            } else if (activeSlide === 2) {
              setCurrentVideo({
                source: "https://player.vimeo.com/video/706365581?h=6a10e91787&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1"
              })
            } else {
              setCurrentVideo({
                source: "https://player.vimeo.com/video/873601736?h=6a10e91787&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1"
              })
            }

            setIsPlaying(true)
          }}
        >
          <div className="play-icon">
            <img src={play} />
          </div>
          <div>
            {activeSlide === 0 ? 'Watch teaser' : 'Watch trailer'}
          </div>
        </button>
        <div className="masterclass-indicators">
          <button
            className={`indicator ${activeSlide === 0 && '-active'}`}
            onClick={() => {
              setActiveSlide(0)
              firstSwiper.slideTo(1)
              secondSwiper.slideTo(1)
            }}
          />
          <button
            className={`indicator ${activeSlide === 1 && '-active'}`}
            onClick={() => {
              setActiveSlide(1)
              firstSwiper.slideTo(2)
              secondSwiper.slideTo(2)
            }}
          />
          <button
            className={`indicator ${activeSlide === 2 && '-active'}`}
            onClick={() => {
              setActiveSlide(2)
              firstSwiper.slideTo(3)
              secondSwiper.slideTo(3)
            }}
          />
          <button
            className={`indicator ${activeSlide === 3 && '-active'}`}
            onClick={() => {
              setActiveSlide(3)
              firstSwiper.slideTo(4)
              secondSwiper.slideTo(4)
            }}
          />
        </div>
        <Swiper
          loop
          onSwiper={setSecondSwiper}
          controller={{ control: firstSwiper }}
        >
          <SwiperSlide>
            <h1 className="title">
              Legal Project Management - it’s here to stay
            </h1>
            <p className="subtitle">
              Helga Butcher shares her wisdom from years' of experience about how legal project management principles paired with the right technology can supercharge the efficient delivery of legal services.
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <h1 className="title">
              Legal Operations in Practice
            </h1>
            <p className="subtitle">
              Hear from our experts and clients as they share their experiences from the battlefield about innovation initiatives, and why legal operations is here to stay.
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <h1 className="title">
              The Industry Titans
            </h1>
            <p className="subtitle">
              Join our Visionaries, our Innovators, and our Dreamers as they share their insights on legal innovation.
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <h1 className="title">
              Harnessing the power of technology to become better lawyers
            </h1>
            <p className="subtitle">
              Ralph Baxter talks about the importance of innovation and how it can make legal services better. He highlights the role of modern tools and technology in helping lawyers become better at what they do.
            </p>
          </SwiperSlide>
        </Swiper>
        <div className="speakers-header">
          <div className="label">Speakers</div>
          <div className="learn-more-button">
            <Link to="/learn/">Learn more</Link>
          </div>
        </div>
        <div
          className="speakers"
        >
          <div
            className={`page ${activeSlide === 2 || activeSlide === 3 ? '-inactive' : ''}`}
            style={{ position: 'absolute', top: 0 }}
          >
            <div
              className="speaker"
              onClick={() => {
                setIsPlaying(true)
                setCurrentVideo({
                  source: 'https://player.vimeo.com/video/718639557?h=e9aa74916e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                })
              }}
            >
              <img src={catherine} />
            </div>
            <div
              className="speaker"
              onClick={() => {
                setIsPlaying(true)
                setCurrentVideo({
                  source: 'https://player.vimeo.com/video/718643443?h=09a7dc6124&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                })
              }}
            >
              <img src={helga} />
            </div>
            <div
              className="speaker"
              onClick={() => {
                setIsPlaying(true)
                setCurrentVideo({
                  source: 'https://player.vimeo.com/video/718644038?h=2a87edb5ea&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                })
              }}
            >
              <img src={stephanie} />
            </div>

          </div>
          <div className={`page ${activeSlide !== 2 ? '-inactive' : ''}`}>
            <div
              className="speaker"
              onClick={() => {
                setIsPlaying(true)
                setCurrentVideo({
                  source: 'https://player.vimeo.com/video/708644203?h=8a1ce96fcd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                })
              }}
            >
              <img src={lars} />
            </div>
            <div
              className="speaker"
              onClick={() => {
                setIsPlaying(true)
                setCurrentVideo({
                  source: 'https://player.vimeo.com/video/708644405?h=84f877b72e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                })
              }}
            >
              <img src={david} />
            </div>
            <div
              className="speaker"
              onClick={() => {
                setIsPlaying(true)
                setCurrentVideo({
                  source: 'https://player.vimeo.com/video/708794592?h=99b2a6349b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                })
              }}
            >
              <img src={jason} />
            </div>
          </div>
          <div className={`page ${activeSlide !== 3 ? '-inactive' : ''}`}
            style={{ position: 'absolute', top: 0 }}
          >
            <div
              className="speaker"
              onClick={() => {
                setIsPlaying(true)
                setCurrentVideo({
                  source: 'https://player.vimeo.com/video/873261246?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                })
              }}
            >
              <img src={chris} />
            </div>
            <div
              className="speaker"
              onClick={() => {
                setIsPlaying(true)
                setCurrentVideo({
                  source: 'https://player.vimeo.com/video/873601736?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                })
              }}
            >
              <img src={ralph} />
            </div>
            <div className="speaker" />
          </div>
        </div>
        {isPlaying &&
            (<MobilePlayer
              onClose={() => setIsPlaying(false)}
              currentVideo={currentVideo}
            />)
        }
      </div>
    </div>
  )
}

export default MasterclassMobile2;
