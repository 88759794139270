import React, { useState } from 'react';

import { Helmet } from 'react-helmet';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Header from '../components/Header';

import '../assets/stylesheets/application.scss';
import VideoSection from '../components/home/VideoSection';
import Steering from '../components/home/Steering';
import Masterclass from '../components/home/Masterclass';
import MasterclassMobile from '../components/home/MasterclassMobile';
import Solutions from '../components/home/Solutions';
import Certifications from '../components/home/Certifications';
import Footer from '../components/Footer';
import Prefooter from '../components/home/Prefooter';
import Playbook from '../components/home/Playbook';
import Integration from '../components/home/Integration';
import CookieConsent from '../components/CookieConsent';
import { GOOGLE_RECAPTCHA_KEY } from '../utils/variables';

export default function Home() {
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Helmet bodyAttributes={{ class: 'page-home' }}>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        <title>Fibonacci - Work beautifully</title>
        <meta name="title" content="Keep work moving, without the chaos" />
        <meta name="description" content="A better way to work, together." />
        <meta name="image" content="/metaimages/homepage.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://fibonacci.legal/" />
        <meta property="og:title" content="Keep work moving, without the chaos" />
        <meta property="og:description" content="A better way to work, together." />
        <meta property="og:image" content="https://www.fibonacci.legal/metaimages/homepage.jpg" />
      </Helmet>
      <div className="content-wrapper -home">
        <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_KEY}>
          <Header
            path="/"
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            showModal={showModal}
            setShowModal={setShowModal}
          />
          <main>
            <VideoSection
              showMenu={showMenu}
              setShowMenu={setShowMenu}
              showModal={showModal}
              setShowModal={setShowModal}
            />
            <Steering />
            <Solutions />
            <Integration />
            <div className="lg-show">
              <Masterclass />
            </div>
            <div className="sm-show">
              <MasterclassMobile />
            </div>
            <Playbook />
            <Certifications />
            <Prefooter />
          </main>
          <Footer />
          <CookieConsent />
        </GoogleReCaptchaProvider>
      </div>
    </>
  );
}
