import { Link } from 'gatsby';
import React from 'react';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import Card from './Card';

import sales1 from '../../assets/images/icons/01-sales.svg';
import sales2 from '../../assets/images/icons/02-sales.svg';
import sales3 from '../../assets/images/icons/03-sales.svg';
import sales4 from '../../assets/images/icons/04-sales.svg';
import sales5 from '../../assets/images/icons/05-sales.svg';
import sales6 from '../../assets/images/icons/06-sales.svg';

export default function Solutions() {
  return (
    <div className="solutions-section">
      <div className="container">
        <div className="features-large">
          <ParallaxProvider>
            <Parallax
              x={[20, 0]}
            >
              <div className="features">
                <Card
                  icon={sales1}
                  title="Your legal technology tool-kit, integrated and enhanced"
                  description="Built on modern technology, Fibonacci can integrate with your most loved technologies."
                />
                <Card
                  icon={sales2}
                  title="No more law firm ‘portals’"
                  description="Let’s face it, many of these are glorified document repositories and generic ‘information’ sites."
                />
                <Card
                  icon={sales3}
                  title="Get a bird’s eye view of all your matters"
                  description="View every workstream, deliverable and task in any way you want. Sort, filter and review key actions across your internal and client teams."
                />
              </div>
            </Parallax>
            <Parallax
              x={[-20, 0]}
            >
              <div className="features">
                <Card
                  icon={sales4}
                  title="Know what everyone is working on"
                  description="Always know what’s ‘next’. Stay on top of important deliverables and spot bottlenecks and issues before they cause problems."
                />
                <Card
                  icon={sales5}
                  title="Create playbooks to share know-how"
                  description="No longer start from scratch when it comes to creating the infrastructure behind service offerings."
                />
                <Card
                  icon={sales6}
                  title="Work better, together"
                  description="Fibonacci is configurable to provide for the plethora of ways that people work. Finally, one place where everyone can work together towards a common goal."
                />
              </div>
            </Parallax>
          </ParallaxProvider>
        </div>
        <div className="features-small">
          <div className="features">
            <Card
              icon={sales1}
              title="Your legal technology tool-kit, integrated and enhanced"
              description="Built on modern technology, Fibonacci can integrate with your most loved technologies."
            />
            <Card
              icon={sales2}
              title="No more law firm ‘portals’"
              description="Let’s face it, many of these are glorified document repositories and generic ‘information’ sites."
            />
            <Card
              icon={sales3}
              title="Get a bird’s eye view of all your matters"
              description="View every workstream, deliverable and task in any way you want. Sort, filter and review key actions across your internal and client teams."
            />
            <Card
              icon={sales4}
              title="Know what everyone is working on"
              description="Always know what’s ‘next’. Stay on top of important deliverables and spot bottlenecks and issues before they cause problems."
            />
            <Card
              icon={sales5}
              title="Create playbooks to share know-how"
              description="No longer start from scratch when it comes to creating the infrastructure behind service offerings."
            />
            <Card
              icon={sales6}
              title="Work better, together"
              description="Fibonacci is configurable to provide for the plethora of ways that people work. Finally, one place where everyone can work together towards a common goal."
            />
          </div>
        </div>

        <div className="button-section">
          <Link to="/features/"><button className="btn-features">See all features</button></Link>
        </div>
      </div>
    </div>
  )
}
