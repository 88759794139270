import React from 'react';

export default function Card({ title, description, icon }) {
  return (
    <div className={`item -active`}>
      <div className="text">
        <div className="icon -autopilot">
          <img src={icon} />
        </div>
        <h1 style={{ marginBottom: '10px' }}>{title}</h1>
        <p>{description}</p>
      </div>
    </div>
  )
}
