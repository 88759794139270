import React, { useRef, useState, useEffect, Fragment } from 'react'

import { Link } from 'gatsby'
import VimeoPlayer from '@vimeo/player'

import SwiperCore, { Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import play from "../../assets/images/icons/play.svg";
import masterclassLogo from "../../assets/images/masterclass/masterclass.png"
import byLaVenturesLogo from "../../assets/images/by-la-ventures.png"
import teaser1 from "../../assets/images/masterclass/teaser-1.png"
import teaser2 from "../../assets/images/masterclass/teaser-2.png"
import teaser3 from "../../assets/images/masterclass/teaser-3.jpg"
import teaser4 from "../../assets/images/masterclass/teaser-4.png"
import teaser5 from "../../assets/images/masterclass/teaser-5.png"
import lars from "../../assets/images/masterclass/lars-rasmussen.png"
import david from "../../assets/images/masterclass/david-kerr.png"
import jason from "../../assets/images/masterclass/jason-barnwell.png"
import stephaniechris from "../../assets/images/masterclass/stephanie-hamon-chris-grant.png"
import paul from "../../assets/images/masterclass/paul-hogg.png"
import catherine from "../../assets/images/masterclass/catherine-goodman.png"
import helga from "../../assets/images/masterclass/helga-butcher.png"
import stephanie from "../../assets/images/masterclass/stephanie-hamon.png"
import chris from "../../assets/images/masterclass/chris-grant-2.png"
import ralph from "../../assets/images/masterclass/ralph-baxter-2.png"
import ArrowLeftIcon from '../../assets/svg/arrow-left.svg';
import ArrowRightIcon from '../../assets/svg/arrow-right.svg';

const Speaker = ({ style, name, title, position, duration, onClick, speakers }) => {
  const [height, setHeight] = useState(372)
  const el = useRef(null)

  useEffect(() => {
    setHeight(el.current.clientWidth * 1.56)
  }, [])

  return (
    <div className="speaker" style={{ ...style, height }} onClick={onClick} ref={el}>
      {
        speakers ? speakers.map(s => (
          <Fragment key={s.name}>
            <div className="name">{s.name}</div>
            <div className="details">{s.position}</div>
          </Fragment>
        )) : (
          <>
            <div className="name">{name}</div>
            <div className="details">{position}</div>
          </>
        )
      }
      <div className="spacer"/>
      <div className="details">{title}</div>
      <div className="spacer" />
      <div className="details">{duration}</div>
    </div>
  )
}

const Player = ( {currentVideo: {source, getStartedText, getStartedURL}} ) => {
  const [initialSource, setInitialSource] = useState(null)
  const player = useRef(null)
  const [playing, setPlaying] = useState(true)
  const playerController = useRef(null)

  useEffect(() => {
    if (!initialSource && source) {
      setInitialSource(source)
    }

    setPlaying(true)
    const player = document.getElementById('vimeo-iframe')
    if (player != null) {
      playerController.current = new VimeoPlayer(player)

      playerController.current.on('pause', () => {
        setPlaying(false)
      })
      playerController.current.on('play', () => {
        setPlaying(true)
      })
    }

    if (playerController.current != null && source != initialSource) {
      playerController.current.loadVideo(source).then(() => {
        playerController.current.play()
      })
    }
  }, [source, initialSource])

  return (
    <div className="teaser-player">
      <div
        style={{ padding: '42.19% 0 0 0', position: 'relative' }}
      >
        {initialSource && (
          <iframe
            id="vimeo-iframe"
            ref={player}
            src={initialSource}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', borderRadius: '8px' }}
            title="Teaser 1 - Visionaries, Innovators, and Dreamers">
          </iframe>
        )}
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
      {
        !playing && (
          <div className="overlay">
            <h3 className="get-started-label">
              {getStartedText}
            </h3>
            <Link
              className="get-started-link"
              to={getStartedURL}
            >
              Get Started
            </Link>
          </div>
        )
      }
    </div>
  )
}

const Masterclass = () => {
  const [isPlaying, setIsPlaying] = useState(false)

  const [currentVideo, setCurrentVideo] = useState(null)

  return (
    <div className="masterclass-section">
      <div className="masterclass-teaser-section">
        <div className="fit-width teaser-container">
          <div className="header">
            <img className="logo" src={masterclassLogo} height="80px" />
            <img className="logo" src={byLaVenturesLogo} height="24px" />
          </div>
          {isPlaying ? (
            <Player
              currentVideo={currentVideo}
            />
          ) : (
            <div className="teaser">
              <div className="thumbnail">
                <img src={teaser1} />
                <button
                  className="play-button center -white"
                  onClick={() => {
                    setIsPlaying(true)
                    setCurrentVideo({
                      source: "https://player.vimeo.com/video/706365581?h=6a10e91787&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1",
                      getStartedText: "Watch Masterclass now",
                      getStartedURL: "/learn"
                    })
                  }}
                >
                  <img src={play} className="icon" alt="play icon" />
                </button>
              </div>
              <div className="content">
                <div className="wrapper">
                  <button
                    className="watch-button"
                    onClick={() => {
                      setIsPlaying(true)
                      setCurrentVideo({
                        source: "https://player.vimeo.com/video/706365581?h=6a10e91787&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1",
                        getStartedText: "Watch Masterclass now",
                        getStartedURL: "/learn"
                      })
                    }}
                  >
                    <div className="play-icon">
                      <img src={play} />
                    </div>
                    <div>Watch Trailer</div>
                  </button>
                  <h3>The Industry Titans</h3>
                  <p>
                    Join our Visionaries, our Innovators, and our Dreamers as they share their insights on legal innovation.
                    <br />
                    <br />
                    #legaltech, it’s time!
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="speakers-header">
            <div className="header">
              <div className="label">Speakers</div>
              <div className="learn-more-button">
                <Link className="btn" to="/learn/">Learn more</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="masterclass-speakers-section">
        <div className="fit-width speakers-container">
          <div className="speakers fit-width">
            <Speaker
              style={{
                backgroundImage: `url(${lars})`,
              }}
              onClick={() => {
                setIsPlaying(true)
                setCurrentVideo({
                  source: 'https://player.vimeo.com/video/708644203?h=8a1ce96fcd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                  getStartedText: `Watch Lars Rasmussen's Masterclass now`,
                  getStartedURL: '/learn/lawyers-and-technologists'
                })
              }}
              name="Lars Rasmussen"
              title={`Lawyers and Technologists${'\n'}the twain shall meet`}
              duration="11m 17s"
              position={`Founder of Google Maps${'\n'}Former Director of Engineering for Facebook`}
            />
            <Speaker
              style={{
                backgroundImage: `url(${david})`,
              }}
              onClick={() => {
                setIsPlaying(true)
                setCurrentVideo({
                  source: 'https://player.vimeo.com/video/708644405?h=84f877b72e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                  getStartedText: `Watch David Kerr's Masterclass now`,
                  getStartedURL: '/learn/judgment-day'
                })
              }}
              name="David Kerr"
              title={`Judgment Day${'\n'}the rise of the tech-enabled law firm`}
              duration="8m 14s"
              position={`Former CEO of Bird & Bird${'\n'} `}
            />
            <Speaker
              style={{
                backgroundImage: `url(${jason})`,
              }}
              onClick={() => {
                setIsPlaying(true)
                setCurrentVideo({
                  source: 'https://player.vimeo.com/video/708794592?h=99b2a6349b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                  getStartedText: `Watch Jason Barnwell's Masterclass now`,
                  getStartedURL: '/learn/legal-innovation'
                })
              }}
              name="Jason Barnwell"
              duration="6m 30s"
              position={`Microsoft's Corporate Attorney${'\n'}for External and Legal Affairs`}
              title={`Legal Innovation${'\n'}We can do more`}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const Masterclass2 = () => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentVideo, setCurrentVideo] = useState(null)

  const [activeSlide, setActiveSlide] = useState(0)
  const [firstSwiper, setFirstSwiper] = useState(null)
  const [secondSwiper, setSecondSwiper] = useState(null)

  return (
    <div className="masterclass-section">
      <div className="masterclass-teaser-section">
        <div className="fit-width teaser-container">
          <div className="header">
            <img className="logo" src={masterclassLogo} height="80px" />
            <img className="logo" src={byLaVenturesLogo} height="24px" />
          </div>
          {isPlaying && (
            <Player
              currentVideo={currentVideo}
            />
          )}
          <div style={{ display: isPlaying ? 'none' : 'block' }}>
            <Swiper
              loop
              allowTouchMove={false}
              onSwiper={setFirstSwiper}
              onSlideChange={(s) => {
                setIsPlaying(false)
              }}
            >
              <SwiperSlide>
                <div className="teaser">
                  <div className="thumbnail">
                    <img src={teaser3} />
                    <button
                      className="play-button center -white"
                      onClick={() => {
                        setIsPlaying(true)
                        setCurrentVideo({
                          source: "https://player.vimeo.com/video/718643443?h=09a7dc6124&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1",
                          getStartedText: "Watch Helga Butcher's Masterclass now",
                          getStartedURL: "/learn/legal-project-management"
                        })
                      }}
                    >
                      <img src={play} className="icon" alt="play icon" />
                    </button>
                  </div>
                  <div className="content">
                    <div className="wrapper">
                      <button
                        className="watch-button"
                        onClick={() => {
                          setIsPlaying(true)
                          setCurrentVideo({
                            source: "https://player.vimeo.com/video/718643443?h=09a7dc6124&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1",
                            getStartedText: "Watch Helga Butcher's Masterclass now",
                            getStartedURL: "/learn/legal-project-management"
                          })
                        }}
                      >
                        <div className="play-icon">
                          <img src={play} />
                        </div>
                        <div>Watch Teaser</div>
                      </button>
                      <h3>Legal Project Management <br />- it's here to stay</h3>
                      <p>
                        Helga Butcher shares her wisdom from years' of experience about how legal project management principles paired with the right technology can supercharge the efficient delivery of legal services.
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="teaser">
                  <div className="thumbnail">
                    <img src={teaser2} />
                    <button
                      className="play-button center -white"
                      onClick={() => {
                        setIsPlaying(true)
                        setCurrentVideo({
                          source: "https://player.vimeo.com/video/718985745?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1",
                          getStartedText: "Watch Masterclass now",
                          getStartedURL: "/learn"
                        })
                      }}
                    >
                      <img src={play} className="icon" alt="play icon" />
                    </button>
                  </div>
                  <div className="content">
                    <div className="wrapper">
                      <button
                        className="watch-button"
                        onClick={() => {
                          setIsPlaying(true)
                          setCurrentVideo({
                            source: "https://player.vimeo.com/video/718985745?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1",
                            getStartedText: "Watch Masterclass now",
                            getStartedURL: "/learn"
                          })
                        }}
                      >
                        <div className="play-icon">
                          <img src={play} />
                        </div>
                        <div>Watch Trailer</div>
                      </button>
                      <h3>Legal Operations in Practice</h3>
                      <p>
                        Hear from our experts and clients as they share their experiences from the battlefield about innovation initiatives, and why legal operations is here to stay.
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="teaser">
                  <div className="thumbnail">
                    <img src={teaser1} />
                    <button
                      className="play-button center -white"
                      onClick={() => {
                        setIsPlaying(true)
                        setCurrentVideo({
                          source: "https://player.vimeo.com/video/706365581?h=6a10e91787&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1",
                          getStartedText: "Watch Masterclass now",
                          getStartedURL: "/learn"
                        })
                      }}
                    >
                      <img src={play} className="icon" alt="play icon" />
                    </button>
                  </div>
                  <div className="content">
                    <div className="wrapper">
                      <button
                        className="watch-button"
                        onClick={() => {
                          setIsPlaying(true)
                          setCurrentVideo({
                            source: "https://player.vimeo.com/video/706365581?h=6a10e91787&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1",
                            getStartedText: "Watch Masterclass now",
                            getStartedURL: "/learn"
                          })
                        }}
                      >
                        <div className="play-icon">
                          <img src={play} />
                        </div>
                        <div>Watch Trailer</div>
                      </button>
                      <h3>The Industry Titans</h3>
                      <p>
                        Join our Visionaries, our Innovators, and our Dreamers as they share their insights on legal innovation.
                        <br />
                        <br />
                        #legaltech, it’s time!
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="teaser">
                  <div className="thumbnail">
                    <img src={teaser5} />
                    <button
                      className="play-button center -white"
                      onClick={() => {
                        setIsPlaying(true)
                        setCurrentVideo({
                          source: "https://player.vimeo.com/video/873601736?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1",
                          getStartedText: "Watch Masterclass now",
                          getStartedURL: "/learn"
                        })
                      }}
                    >
                      <img src={play} className="icon" alt="play icon" />
                    </button>
                  </div>
                  <div className="content">
                    <div className="wrapper">
                      <button
                        className="watch-button"
                        onClick={() => {
                          setIsPlaying(true)
                          setCurrentVideo({
                            source: "https://player.vimeo.com/video/873601736?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1",
                            getStartedText: "Watch Masterclass now",
                            getStartedURL: "/learn"
                          })
                        }}
                      >
                        <div className="play-icon">
                          <img src={play} />
                        </div>
                        <div>Watch Trailer</div>
                      </button>
                      <h3>Harnessing the power of technology to become better lawyers</h3>
                      <p>
                        Ralph Baxter talks about the importance of innovation and how it can make legal services better. He highlights the role of modern tools and technology in helping lawyers become better at what they do.
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="masterclass-indicators">
            <button
              className={`indicator ${activeSlide === 0 && '-active'}`}
              onClick={() => {
                setActiveSlide(0)
                firstSwiper.slideTo(1)
                secondSwiper.slideTo(1)
              }}
            />
            <button
              className={`indicator ${activeSlide === 1 && '-active'}`}
              onClick={() => {
                setActiveSlide(1)
                firstSwiper.slideTo(2)
                secondSwiper.slideTo(1)
              }}
            />
            <button
              className={`indicator ${activeSlide === 2 && '-active'}`}
              onClick={() => {
                setActiveSlide(2)
                firstSwiper.slideTo(3)
                secondSwiper.slideTo(2)
              }}
            />
            <button
              className={`indicator ${activeSlide === 3 && '-active'}`}
              onClick={() => {
                setActiveSlide(3)
                firstSwiper.slideTo(4)
                secondSwiper.slideTo(3)
              }}
            />
          </div>
          <div className="speakers-header">
            <div className="header">
              <div className="label">Speakers</div>
              <div className="learn-more-button">
                <Link className="btn" to="/learn/">Learn more</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="masterclass-speakers-section">
        <div className="fit-width speakers-container">
          <Swiper
            loop
            allowTouchMove={false}
            onSwiper={setSecondSwiper}
          >
            <SwiperSlide>
              <div className="speakers">
                <Speaker
                  style={{
                    backgroundImage: `url(${stephaniechris})`,
                  }}
                  onClick={() => {
                    setIsPlaying(true)
                    setCurrentVideo({
                      source: 'https://player.vimeo.com/video/718640127?h=2bbd968b86&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                      getStartedText: `Watch Stéphanie Hammon and Chris Grant's Masterclass now`,
                      getStartedURL: '/learn/legal-ops-whats-the-fuss'
                    })
                  }}
                  speakers={[
                    {name: 'Stéphanie Hamon', position: `Head of Legal Operations Consulting,${'\n'}Norton Rose Fulbright`},
                    {name: 'Chris Grant', position: `Lawtech / LegalOps Consultant${'\n'}for startups & law firms`},
                  ]}
                  title={`LegalOps ${'\n'}what's the fuss`}
                  duration="20m 20s"
                />
                <Speaker
                  style={{
                    backgroundImage: `url(${paul})`,
                  }}
                  onClick={() => {
                    setIsPlaying(true)
                    setCurrentVideo({
                      source: 'https://player.vimeo.com/video/718649920?h=34d76be563&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                      getStartedText: `Watch Paul Hogg's Masterclass now`,
                      getStartedURL: '/learn/panel-firms'
                    })
                  }}
                  name="Paul Hogg"
                  title={`Panel firms${'\n'}It's all about the relationships!`}
                  duration="9m 27s"
                  position={`Relationship Manager,${'\n'}Barclays`}
                />
                <Speaker
                  style={{
                    backgroundImage: `url(${catherine})`,
                  }}
                  onClick={() => {
                    setIsPlaying(true)
                    setCurrentVideo({
                      source: 'https://player.vimeo.com/video/718639557?h=e9aa74916e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                      getStartedText: `Watch Catherine Goodman's Masterclass now`,
                      getStartedURL: '/learn/paving-the-way-for-legaltech-adoption'
                    })
                  }}
                  name="Catherine Goodman"
                  duration="5m 37s"
                  position={`Practice Innovation & Knowledge${'\n'}Counsel, Paul Hastings`}
                  title={`Paving the way for${'\n'}#legaltech adoption`}
                />
                <Speaker
                  style={{
                    backgroundImage: `url(${helga})`,
                  }}
                  onClick={() => {
                    setIsPlaying(true)
                    setCurrentVideo({
                      source: 'https://player.vimeo.com/video/718643443?h=09a7dc6124&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                      getStartedText: `Watch Helga Butcher's Masterclass now`,
                      getStartedURL: '/learn/legal-project-management'
                    })
                  }}
                  name="Helga Butcher"
                  duration="8m 13s"
                  position={`Head of Legal Project Management,${'\n'}Ashurst`}
                  title={`Legal Project Management${'\n'}it's here to stay`}
                />
                <Speaker
                  style={{
                    backgroundImage: `url(${stephanie})`,
                  }}
                  onClick={() => {
                    setIsPlaying(true)
                    setCurrentVideo({
                      source: 'https://player.vimeo.com/video/718644038?h=2a87edb5ea&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                      getStartedText: `Watch Stéphanie Hamon's Masterclass now`,
                      getStartedURL: '/learn/legal-ops-a-framework-for-success'
                    })
                  }}
                  name="Stéphanie Hamon"
                  duration="12m 37s"
                  position={`Head of Legal Operations Consulting,${'\n'}Norton Rose Fulbright`}
                  title={`LegalOps${'\n'}a framework for success`}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="speakers">
                <div />
                <Speaker
                  style={{
                    backgroundImage: `url(${lars})`,
                  }}
                  onClick={() => {
                    setIsPlaying(true)
                    setCurrentVideo({
                      source: 'https://player.vimeo.com/video/708644203?h=8a1ce96fcd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                      getStartedText: `Watch Lars Rasmussen's Masterclass now`,
                      getStartedURL: '/learn/lawyers-and-technologists'
                    })
                  }}
                  name="Lars Rasmussen"
                  title={`Lawyers and Technologists${'\n'}the twain shall meet`}
                  duration="11m 17s"
                  position={`Founder of Google Maps${'\n'}Former Director of Engineering for Facebook`}
                />
                <Speaker
                  style={{
                    backgroundImage: `url(${david})`,
                  }}
                  onClick={() => {
                    setIsPlaying(true)
                    setCurrentVideo({
                      source: 'https://player.vimeo.com/video/708644405?h=84f877b72e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                      getStartedText: `Watch David Kerr's Masterclass now`,
                      getStartedURL: '/learn/judgment-day'
                    })
                  }}
                  name="David Kerr"
                  title={`Judgment Day${'\n'}the rise of the tech-enabled law firm`}
                  duration="8m 14s"
                  position={`Former CEO of Bird & Bird${'\n'} `}
                />
                <Speaker
                  style={{
                    backgroundImage: `url(${jason})`,
                  }}
                  onClick={() => {
                    setIsPlaying(true)
                    setCurrentVideo({
                      source: 'https://player.vimeo.com/video/708794592?h=99b2a6349b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                      getStartedText: `Watch Jason Barnwell's Masterclass now`,
                      getStartedURL: '/learn/legal-innovation'
                    })
                  }}
                  name="Jason Barnwell"
                  duration="6m 30s"
                  position={`Microsoft's Corporate Attorney${'\n'}for External and Legal Affairs`}
                  title={`Legal Innovation${'\n'}We can do more`}
                />
                <div />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div style={{ gridTemplateColumns: "repeat(4, 1fr)" }} className="speakers">
                <div />
                <Speaker
                  style={{
                    backgroundImage: `url(${chris})`,
                  }}
                  onClick={() => {
                    setIsPlaying(true)
                    setCurrentVideo({
                      source: 'https://player.vimeo.com/video/873261246?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                      getStartedText: `Watch Chris Grant's Masterclass now`,
                      getStartedURL: '/learn/digital-transformation'
                    })
                  }}
                  name="Chris Grant"
                  title={`Digital Transformation and the${'\n'}Modernisation of Legal Operations`}
                  duration="10m 53s"
                  position={`Investor & Advisor`}
                />
                <Speaker
                  style={{
                    backgroundImage: `url(${ralph})`,
                  }}
                  onClick={() => {
                    setIsPlaying(true)
                    setCurrentVideo({
                      source: 'https://player.vimeo.com/video/873601736?h=719b5d6ba6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479;autoplay=1',
                      getStartedText: `Watch Ralph Baxter's Masterclass now`,
                      getStartedURL: '/learn/harnessing-the-power-of-technology'
                    })
                  }}
                  name="Ralph Baxter"
                  duration="23m 33s"
                  position={`Former Chairman and CEO,${'\n'}of Orrick`}
                  title={`Harnessing the power of technology to${'\n'}become better lawyers`}
                />
              </div>
            </SwiperSlide>
          </Swiper>
          <button
            className="arrow-button"
            style={{
              position: 'absolute',
              left: -8,
              top: '50%',
              transform: 'translateY(-50%)',
            }}
            onClick={() => {
              setIsPlaying(false)

              const prev = ((activeSlide - 1) + 4) % 4
              setActiveSlide(prev)

              // slideTo is 1 base
              firstSwiper.slideTo(prev + 1)

              if (prev === 0 || prev === 1) {
                secondSwiper.slideTo(1)
              } else if (prev === 2) {
                secondSwiper.slideTo(2)
              } else {
                secondSwiper.slideTo(3)
              }
            }}
          >
            <ArrowLeftIcon fill="black" />
          </button>
          <button
            className="arrow-button"
            onClick={() => {
              setIsPlaying(false)
              const next = ((activeSlide + 1)) % 4
              setActiveSlide(next)

              // slideTo is 1 base
              firstSwiper.slideTo(next + 1)

              if (next === 0 || next === 1) {
                secondSwiper.slideTo(1)
              } else if (next === 2) {
                secondSwiper.slideTo(2)
              } else {
                secondSwiper.slideTo(3)
              }
            }}
            style={{
              position: 'absolute',
              right: -8,
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            <ArrowRightIcon fill="black" />
          </button>

        </div>
      </div>
    </div>
  )
}

export default Masterclass2;
