import React, { useEffect, useRef } from 'react'

import homeVideo from '../assets/videos/fibonacci-home.mp4'
import close from '../assets/images/close-white.svg';

export default ({ open, onClose }) => {
  const videoElement = useRef(null);

  const closeFn = () => {
    videoElement.current.pause();
    onClose();
  };

  useEffect(() => {
    const escapeListener = (event) => {
      if (event.key === 'Escape') {
        closeFn();
      }
    }

    window.addEventListener('keydown', escapeListener);

    return () => {
      window.removeEventListener('keydown', escapeListener);
    }
  })

  return(
    <div className={`video-player ${open ? '-open' : ''}`}>
      <div className="container">
        <button
          className="button"
          onClick={() => closeFn()}
        >
          <img src={close} className="icon" alt="close" />
          <div className="text">Close</div>
        </button>
        <video controls className="video" ref={videoElement}>
          <source src={homeVideo} type="video/mp4" />
        </video>
      </div>
    </div>
  )
}
