import { Link } from 'gatsby';
import React from 'react';

import shape3 from '../../assets/images/shape-3.svg';
import shape4 from '../../assets/images/shape-4.svg';
import shape6 from '../../assets/images/shape-6.svg';
import shape7 from '../../assets/images/shape-7.svg';

const Prefooter = () => (
  <div className="get-started">
    <div className="content">
      <div className="title" style={{ marginBottom: '0px' }}>Great work necessitates</div>
      <div className="title">better teamwork</div>
    </div>
    <div className="content">
      <Link style={{ textDecoration: 'none' }} to="/features/">
        <button
          style={{
            background: "#1E1A39",
            borderRadius: "6px",
            padding: '10px 15px',
            color: 'white'
          }}
        >See how Fibonacci works</button></Link>
    </div>
    <img className="topright" src={shape3} alt="" />
    <img className="bottomleft" src={shape4} alt="" />

    <img className="mobileleft" src={shape6} alt="" />
    <img className="mobileright" src={shape7} alt="" />
  </div>
);

export default Prefooter;
