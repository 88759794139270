import React from 'react';
import loop2 from '../../assets/videos/FIBONACCI_SCENE3_SHOT6_LOOP.mp4';
import serverLogo from '../../assets/images/icons/m_a.svg';
import dataLogo from '../../assets/images/icons/IP.svg';
import encryptionLogo from '../../assets/images/icons/property.svg';
import authenticationLogo from '../../assets/images/icons/tech.svg';
import employmentLogo from '../../assets/images/employment.svg';
import dataProtectionLogo from '../../assets/images/data_protection.svg';
import chrisGrant from '../../assets/images/chris-grant.jpg';
import barclaysEagleLabs from '../../assets/images/barclays-eagle-labs-logo_500x300-colored.png';
import Quote from '../Quote';
import { Link } from 'gatsby';

export default function Playbook() {
  return (
    <div className="playbook-section">
      <div className="flexible-container">
        <video className="video" autoPlay loop muted playsInline>
          <source src={loop2} type="video/mp4" />
          <source src={loop2} type="video/ogg" />
        </video>
        <div className="wrapper">
          <div className="container">
            <div className="header">The easiest and most powerful way to playbook your legal services</div>
            <div className="content">
              Unprecedented visibility around key service offerings saves time, communicates know-how, and limits mistakes.  From fast moving M&A, to complex multi-jurisdictional litigation, every service line can be playbooked!
          </div>
            <div className="section">
              <div className="content">
                <img className="logo _mb-16" src={serverLogo} alt="" />
                <div className="_font-16 _bold _mb-8">Mergers & Acquisitions</div>
              </div>
              <div className="content">
                <img className="logo _mb-16" src={encryptionLogo} alt="" />
                <div className="_font-16 _bold _mb-8">Property</div>
              </div>
            </div>
            <div className="section">
              <div className="content">
                <img className="logo _mb-16" src={dataLogo} alt="" />
                <div className="_font-16 _bold _mb-8">Intellectual Property</div>
              </div>
              <div className="content">
                <img className="logo _mb-16" src={authenticationLogo} alt="" />
                <div className="_font-16 _bold _mb-8">Technology</div>
              </div>
            </div>
            <div className="section">
              <div className="content">
                <img className="logo _mb-16" src={employmentLogo} alt="" />
                <div className="_font-16 _bold _mb-8">Employment</div>
              </div>
              <div className="content">
                <img className="logo _mb-16" src={dataProtectionLogo} alt="" />
                <div className="_font-16 _bold _mb-8">Data Protection</div>
              </div>
            </div>
            <div className="button-section">
              <Link style={{ textDecoration: 'none' }} to="/features/"><button className="base-button -primary"
              >See all features</button></Link>
            </div>
          </div>
        </div>
      </div>
      <Quote
        author="Chris Grant"
        position="Law Tech Director, Barclays"
        avatar={chrisGrant}
        logo={barclaysEagleLabs}
        content="Accessing our law firms’ playbooks for different service areas has allowed us to fast-track engagement, collaborate more effectively, and allows our external advisors to become more ingrained as members of our internal legal team."
      />
    </div>
  )
}
